// Obtiene el nombre del mes actual
function getCurrentMonthName() {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return months[new Date().getMonth()];
}

// Obtiene el año actual
function getCurrentYear() {
    return new Date().getFullYear();
}

// Genera el nombre del video con el formato deseado
function generateVideoName() {
    const month = getCurrentMonthName();
    const year = getCurrentYear();
    return `Tv Misiones - Monitoreo canales noticias ${month} ${year}`;
}

// Define las fechas dinámicas
const currentDate = new Date();
const startDate = new Date(currentDate.getTime() - 60 * 60 * 1000); // 60 minutos antes del momento actual
const endDate = new Date(currentDate.getTime() + 5 * 60 * 60 * 1000); // 5 horas después del momento actual
const expirationDate = new Date(currentDate.getFullYear() + 1, 11, 31, 23, 59, 59); // Un año después de la fecha actual

// Define el JSON-LD con las fechas y el nombre del video generado dinámicamente
const jsonLD = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "contentURL": window.location.origin,
    "description": "Las últimas novedades en Misiones. Monitoreo de los canales de noticias online más importantes de la región. Desde política hasta eventos locales.",
    "duration": "PT24H",
    "embedUrl": window.location.origin ,
    "expires": expirationDate.toISOString(), // Fecha de expiración dinámica
    "regionsAllowed": "AR",
    "interactionStatistic": {
        "@type": "InteractionCounter",
        "interactionType": { "@type": "WatchAction" },
        "userInteractionCount": 12000
    },
    "name": generateVideoName(), // Genera el nombre del video dinámicamente
    "thumbnailUrl": window.location.origin + "/logo.png",
    "uploadDate": currentDate.toISOString(), // Convierte la fecha actual a ISOString
    "publication": [
        {
            "@type": "BroadcastEvent",
            "isLiveBroadcast": true,
            "startDate": startDate.toISOString(), // Convierte la fecha de inicio a ISOString
            "endDate": endDate.toISOString(), // Convierte la fecha de finalización a ISOString
            "isAccessibleForFree": true
        },
        {
            "@type": "BroadcastEvent",
            "isLiveBroadcast": true,
            "startDate": startDate.toISOString(), // Convierte la fecha de inicio a ISOString
            "endDate": endDate.toISOString(), // Convierte la fecha de finalización a ISOString
            "isAccessibleForFree": true
        }
    ]
};

// Imprime el JSON-LD en el documento HTML
const script = document.createElement('script');
script.type = 'application/ld+json';
script.textContent = JSON.stringify(jsonLD);
document.head.appendChild(script);
